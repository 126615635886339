<template>
  <v-container fluid>
    <h2>Geräteaufstellung {{ wkteam }}</h2>
    <v-card
      v-for="d in disciplines"
      :key="d._id"
    >
      <v-card-text>
        <geraet
          :did="d._id"
          :team="team"
          :id="id"
          :e="e"
          :df="df"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'start',

  components: {
    Geraet: () => import('./geraet')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    disciplines () {
      return (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order
      })) || [])
        .filter(d => d.active && d.regular)
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
    },
    wkteam () {
      return this.e?.teams?.find(t => t.team._id === this.team)?.team?.name
    }
  }
}
</script>

<style scoped>

</style>
